import React from "react";

const Section = ({ children, padding, margin, backgroundColor }) => {
  padding = padding ? " "+padding : " py-4"
  margin = margin ? " "+margin : ""
  backgroundColor = backgroundColor ? backgroundColor : "white"
  if (backgroundColor === "blue") backgroundColor += " text-white"

  return (
    <section className={`bg-${backgroundColor}${padding}${margin}`}>
      { children }
    </section>
  )
}
export default Section
