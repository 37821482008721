import React from "react"
import { siteMetadata } from "../../gatsby-config"
import Section from "../components/Section"
import { Lead } from "../components/ui/utils"
import { StaticImage } from "gatsby-plugin-image"

const Maintenance = () => {
  return (
    <>
      <h2>Pour en savoir plus</h2>
      <Lead>
        Le site du {siteMetadata.title} est en cours de mise à jour.
      </Lead>
      <Lead>
        Vous pouvez nous contacter à l’adresse <a href="mailto:ville-europeenne@jeunes-europeens.org">ville-europeenne@jeunes-europeens.org</a>.
      </Lead>
    </>
  )
}

const Article = () => {
  return (
    <div className="tw-max-w-[100ch] tw-mx-auto">
      <Lead>De l’outil de campagne à la distinction incontournable pour les communes, le Label Ville européenne est loin d’avoir épuisé toutes ses ressources !</Lead>
      <p><strong>C’est à l’occasion des élections municipales de 2020 que les Jeunes Européens - France ont lancé le Label Ville européenne. L’objectif était de sensibiliser aux questions européennes les candidats à la fonction de maire alors que les communes sont le premier échelon de la démocratie et de la citoyenneté européenne.</strong></p>
      <p>En coopération avec le Mouvement Européen - France et l’Union des fédéralistes européens - France, Les Jeunes Européens - France sont partis du constat que les élus municipaux sont des acteurs structurants de la vie et du débat publics et peuvent ainsi mettre en œuvre des politiques d’innovation, grandement influencées par les politiques européennes, au plus proche des citoyens.</p>
      <p>Face à ce potentiel européen, les associations porteuses ont conçu un véritable instrument de promotion de la démocratie européenne dans l’objectif de faire vivre l’esprit européen dans l’ensemble des villes et villages de France. <strong>Le Label, 100% citoyen et transpartisan</strong>, s’adresse à toutes les communes et incite les responsables politiques locaux à mettre en place des actions concrètes et pro européennes réparties en quatre thématiques.</p>
      <p><strong>L’aventure du Label Ville européenne ne s’est pas arrêtée aux élections municipales mais se poursuit vers de nouveaux axes de développement pour toujours plus d’Europe au plus près des citoyens !</strong> Dorénavant encadrés par une Convention, les engagements des élus signataires pour la promotion de la démocratie européenne ne sont plus seulement déclaratoires. Ce texte vient donner une valeur nouvelle au Label Ville européenne et renforce nos objectifs.</p>
      <p>La Charte d’engagement elle-même a fait peau neuve en intégrant le nouvel item Littérature à la thématique Culture. Au programme, inauguration d’un rayon européen dans les bibliothèques, interventions pédagogiques, salon de la littérature européenne et prix du jeune auteur européen ! Les actions ont été minutieusement pensées pour être réalisables par toutes les communes. Patience, la dernière version de la Charte sera bientôt disponible sur le site ville-européenne.org en cours d’actualisation.</p>
      <p><strong>Concrètement, en un an d'existence, quels sont les résultats du Label Ville européenne ?</strong> Aujourd’hui, l’attribution du Label Ville européenne à 40 communes de toutes tailles, urbaines ou rurales, a permis de constituer un réseau solidaire et impliqué pour une Europe des territoires qui continue de s’étendre.</p>
      <p className="tw-max-w-prose tw-mx-auto tw-my-8"><StaticImage src="./../../static/images/photo_plaque_Label_Arras.jpg" alt="La plaque Ville Européenne a été octroyée à la Ville d'Arras." /></p>
      <p>En parallèle, la Plaque Ville européenne a été octroyée aux villes d’Arras, de Nantes, de Paris et de Rouen en récompense de leur engagement fort et novateur. La Plaque ce n’est pas rien ! Elle est un véritable gage de qualité et ne peut être décernée qu’après une évaluation approfondie et la validation des candidatures en Comité de Pilotage qui se tient tous les 3 mois.</p>
      <p>Mettre en œuvre l’Europe c’est bien, la vivre ensemble c’est encore mieux ! Les élus du réseau du Label Ville européenne se réuniront en octobre prochain pour la première édition de la Journée de Partage des Bonnes Pratiques. Cet événement est un temps fort du Label Ville européenne. Structuré autour de ses 4 thématiques, il mettra en avant les aboutissements et les projets européens des communes détentrices de la Plaque et du Label ainsi que la Conférence sur l’avenir de l’Europe, conformément à notre volonté de toujours rendre l’Europe accessible aux citoyens.</p>
    </div>
  )
}

export default function Home() {
  return (
    <>
      <Section padding="pt-5 pb-5">
        <Article />
      </Section>
      <Section padding="pt-5 pb-5">
        <Maintenance />
      </Section>
    </>
  )
}
